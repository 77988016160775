<template>
  <div class="row page-wrapper">
    <div class="col-xs-12 page-header">
      <h1 class="text-center">Services <small>We Offer</small></h1>
    </div>

    <div class="col-md-8 col-md-offset-2">
      <h1 class="h3 brand-color">Shuttle Services</h1>

      <p>
        Who says you can’t have it all? Treat your community to the most ideal
        commute by choosing Ramjay Inc. as your shuttle service provider and let
        heed the woes of rush hour!
      </p>

      <p>
        We proudly serve the Greater Washington Metropolitan area with prompt,
        professional and reliable service, of which we are best known for.
      </p>

      <ul>
        <li>Operating for over a decade servicing over 2000 people a day!</li>
        <li>
          Offer first class service, safety, dependability and convenience.
        </li>
        <li>
          Fleet consists of 15, 25 and 35 passenger buses, sleek 10-15 passenger
          limousines, luxury sedans and SUVs.
        </li>
        <li>Experienced, professional and uniformed chauffeurs.</li>
        <li>
          Provides services to metro stations from apartment
          complexes/businesses throughout the Greater Washington area.
        </li>
        <li>
          Our professional and experienced support staff treat every query or
          concern with highest priority and we treat every customer like family
          from day one.
        </li>
      </ul>

      <p>
        You make the schedules and we will deliver! Known for the best rates, we
        will never fail to impress. Guaranteed! For these reasons and more, a
        shuttle service can be an invaluable asset to any community. For any
        questions or for a quick quote, please do not hesitate to contact us by
        phone or email.
      </p>

      <h1 class="h3 brand-color">Tours &amp; Sightseeing</h1>
      <p>
        Our trained travel specialists will develop and implement the logistical
        planning to move your group effortlessly to and from your event/place of
        interest. We can assist you in settings up day or evening outings to
        D.C. attractions, golf outings, or day trips to one of our regions fine
        wineries for example.
      </p>

      <h1 class="h3 brand-color">Corporate</h1>
      <p>
        Does your company need to secure safe and reliable transportation for
        its employees? We can help! Ramjay provides exclusive transportation
        services to our corporate clients, including sedan, limo, and shuttle
        services.
      </p>

      <h1 class="h3 brand-color">Wedding &amp; Leisure</h1>
      <p>
        Want to make your special day unforgettable? We at Ramjay are committed
        to giving you the utmost in service to make your special day a memory
        that will last a lifetime. We want you to enjoy the festivities as much
        as your guests will. Ramjay Inc. will help design and coordinate a full
        transportation plan for your wedding or other special event.
      </p>

      <ul>
        <li>Pre-wedding outings.</li>
        <li>Rehearsal dinners.</li>
        <li>Wedding day transportation.</li>
        <li>Out-of-town guest pick-up.</li>
        <li>Shuttle guests to and from your event.</li>
      </ul>

      <h1 class="h3 brand-color">Airport Transportation</h1>
      <p>
        Got a flight to catch? You can count on us to get you there on time.
      </p>

      <h1 class="h3 brand-color">Convention &amp; Groups</h1>
      <p>
        Washington DC is one of the top destinations for conventions and groups.
        Ramjay specializes in making your function perfect. We will take care of
        all the logistics, be it picking the guests from the airport, taking
        them to the convention/conference and even taking them around town. Our
        commitment towards excellence and reliability will give your guests and
        yourselves a sense of relaxation as we take care of all the coordination
        necessary to make your event a success.
      </p>

      <h1 class="h3 brand-color">Day Trips &amp; Hires</h1>
      <p>
        Need a car or bus to go on vacation? Call Ramjay and we will make your
        trip an unforgettable experience.
      </p>

      <br />
    </div>

    <!-- <div class="col-md-4">
      <ReservationWidget />
    </div> -->

    <br class="visible-xs visible-sm visible-md" />
  </div>
  <!-- end.page-wrapper -->
</template>

<script>
// import ReservationWidget from "@/views/components/ReservationWidget.vue";

export default {
  name: "Services",

  /*components: {
    ReservationWidget,
  },*/
};
</script>
