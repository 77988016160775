<template>
  <div class="row page-wrapper">
    <div class="col-xs-12 page-header">
      <h1>Vehicle <small>Reservation</small></h1>
    </div>

    <div class="col-xs-12">
      <div class="well notice success">
        <p class="nogap-bottom">
          Reservation successful. You will shortly receive a call from our
          customer support executive.
        </p>
      </div>
      <div class="well notice error">
        <p class="nogap-bottom">
          An unknown error occurred during reservation. Please try again later.
        </p>
      </div>

      <form>
        <div class="row">
          <div class="col-md-6">
            <h3 class="brand-color">Personal Information</h3>
            <hr />

            <div class="form-group">
              <label>Your Name <span class="req">*</span></label>
              <input class="form-control" type="text" required />
              <!-- {{ Form::text('name', null, ['class' => 'form-control', 'required']) }} -->
              <!-- @if($errors->has('name'))
                  <label class="alert-validate alert-error">{{ $errors->first('name') }}</label>
                @endif -->
            </div>

            <div class="form-group">
              <label>Contact Number <span class="req">*</span></label>
              <input class="form-control" type="text" required />
              <!-- {{ Form::text('telephone', null, ['class' => 'form-control', 'required']) }}
                @if($errors->has('telephone'))
                  <label class="alert-validate alert-error">{{ $errors->first('telephone') }}</label>
                @endif -->
            </div>

            <div class="form-group">
              <label>Email Address <span class="req">*</span></label>
              <input class="form-control" type="email" required />
              <!-- {{ Form::email('email', null, ['class' => 'form-control', 'required']) }}
                @if($errors->has('email'))
                  <label class="alert-validate alert-error">{{ $errors->first('email') }}</label>
                @endif -->
            </div>

            <div class="form-group">
              <label>Your Occasion</label>
              <input
                class="form-control"
                type="text"
                placeholder="Ex. Airport Drop-off"
                required
              />
              <!-- {{ Form::select('service', ['' => '--'] + $services, '', ['required']) }}
                @if($errors->has('service'))
                  <label class="alert-validate alert-error">{{ $errors->first('service') }}</label>
                @endif -->
            </div>
          </div>

          <div class="col-md-6">
            <h3 class="brand-color">Vehicle Information</h3>
            <hr />

            <div class="form-group">
              <label>Number of Passenger/s <span class="req">*</span></label>
              <select name="passengers" class="form-control" required>
                <option selected disabled>--</option>
                <option value="1">1</option>
                <option value="2">2</option>
                <option value="3">3</option>
                <option value="4">4</option>
                <option value="6">5</option>
                <option value="More than 5">More than 5</option>
              </select>
              <!-- @if($errors->has('passengers'))
                  <label class="alert-validate alert-error">{{ $errors->first('passengers') }}</label>
                @endif -->
            </div>

            <div class="form-group">
              <label>Vehicle Type <span class="req">*</span></label>
              <select name="vehicle-type" class="form-control" required>
                <option selected disabled>--</option>
                <option value="1">Car</option>
                <option value="2">Van</option>
                <option value="3">Bus</option>
                <option value="4">SUV</option>
                <option value="6">Limousine</option>
              </select>
              <!-- {{ Form::select('vehicle', ['' => '--'] + $vehicles, '', ['required']) }}
                @if($errors->has('vehicle'))
                  <label class="alert-validate alert-error">{{ $errors->first('vehicle') }}</label>
                @endif -->
            </div>

            <div class="form-group">
              <label>Any Special Requests / Comments</label>
              <textarea
                class="form-control"
                rows="4"
                placeholder="We would like to know your special requests, comments, any reasons or choices. Feel free to write us here."
              />
              <!-- {{ Form::textarea('comments', null, ['class' => 'form-control', 'rows' => '4', 'placeholder' => 'We would like to know your special requests, comments, any reasons or choices. Feel free to write us here.']) }}
                @if($errors->has('comments'))
                  <label class="alert-validate alert-error">{{ $errors->first('comments') }}</label>
                @endif -->
            </div>
          </div>
        </div>
        <!-- end.row -->

        <br />

        <div class="row">
          <div class="col-md-6">
            <h3 class="brand-color">Pick-up Information</h3>
            <hr />

            <div class="form-group">
              <div class="form-group">
                <label class="full-width"
                  >City/State <span class="req">*</span></label
                >
                <input
                  class="form-control typeahead full-width"
                  type="text"
                  placeholder="Ex. Virginia"
                  required
                />
                <!-- {{ Form::text('pickup_location', null, ['class' => 'form-control typeahead full-width', 'required', 'placeholder' => 'Ex. Virginia']) }}
                  @if($errors->has('pickup_location'))
                    <label class="alert-validate alert-error">{{ $errors->first('pickup_location') }}</label>
                  @endif -->
              </div>

              <div class="form-group">
                <label>Pick-up Date <span class="req">*</span></label>
                <input
                  class="form-control datetimepicker d-picker"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  required
                  readonly
                />
                <!-- {{ Form::text('pickup_date', null, ['class' => 'form-control datetimepicker d-picker', 'required', 'readonly', 'placeholder' => 'YYYY-MM-DD']) }}
                  @if($errors->has('pickup_date'))
                    <label class="alert-validate alert-error">{{ $errors->first('pickup_date') }}</label>
                  @endif -->
              </div>

              <div class="form-group">
                <label>Pick-up Time <span class="req">*</span></label>
                <input
                  class="form-control datetimepicker t-picker"
                  type="text"
                  placeholder="HH:MM AM/PM"
                  required
                  readonly
                />
                <!-- {{ Form::text('pickup_time', null, ['class' => 'form-control datetimepicker t-picker', 'required', 'readonly', 'placeholder' => 'HH:MM AM/PM']) }}
                  @if($errors->has('pickup_time'))
                    <label class="alert-validate alert-error">{{ $errors->first('pickup_time') }}</label>
                  @endif -->
              </div>
            </div>
          </div>

          <div class="col-md-6">
            <h3 class="brand-color">Drop-off Information</h3>
            <hr />

            <div class="form-group">
              <div class="form-group">
                <label class="full-width">City/State</label>
                <input
                  class="form-control typeahead full-width"
                  type="text"
                  placeholder="Ex. Virginia"
                />
                <!-- {{ Form::text('drop_location', null, ['class' => 'form-control typeahead full-width', 'placeholder' => 'Ex. Virginia']) }}
                  @if($errors->has('drop_location'))
                    <label class="alert-validate alert-error">{{ $errors->first('drop_location') }}</label>
                  @endif -->
              </div>

              <div class="form-group">
                <label>Drop-off Date</label>
                <input
                  class="form-control datetimepicker d-picker"
                  type="text"
                  placeholder="YYYY-MM-DD"
                  readonly
                />
                <!-- {{ Form::text('drop_date', null, ['class' => 'form-control datetimepicker d-picker', 'readonly', 'placeholder' => 'YYYY-MM-DD']) }}
                  @if($errors->has('drop_date'))
                    <label class="alert-validate alert-error">{{ $errors->first('drop_date') }}</label>
                  @endif -->
              </div>

              <div class="form-group">
                <label>Drop-off Time</label>
                <input
                  class="form-control datetimepicker t-picker"
                  type="text"
                  placeholder="HH:MM AM/PM"
                  readonly
                />
                <!-- {{ Form::text('drop_time', null, ['class' => 'form-control datetimepicker t-picker', 'readonly', 'placeholder' => 'HH:MM AM/PM']) }}
                  @if($errors->has('drop_time'))
                    <label class="alert-validate alert-error">{{ $errors->first('drop_time') }}</label>
                  @endif -->
              </div>
            </div>
          </div>
        </div>
        <!-- end.row -->

        <div class="row">
          <div class="col-md-12 text-right">
            <br />
            <button type="button" class="btn btn-default">
              Reserve My Ride
            </button>
            <br /><br /><br />
          </div>
        </div>
        <!-- end.row -->
      </form>
    </div>
  </div>
  <!-- end.page-wrapper -->
</template>

<script>
export default {
  name: "Reservations",
};
</script>
