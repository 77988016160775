<template>
  <div class="row page-wrapper">
    <div class="col-xs-12 page-header">
      <h1>Contact <small>Us</small></h1>
    </div>

    <div class="col-md-7">
      <div
        id="alert-success_contact"
        ref="alert-success_contact"
        class="hidden alert alert-success alert-form alert-form-success alert-dismissable fade in"
      >
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          &times;
        </button>
        We've successfully received your message. We'll contact you soon!
      </div>

      <div
        id="alert-error_contact"
        class="hidden alert alert-error alert-form alert-form-error alert-dismissable fade in"
      >
        <button
          type="button"
          class="close"
          data-dismiss="alert"
          aria-hidden="true"
        >
          &times;
        </button>
        Oops.. Something went wrong! Please try again later.
      </div>

      <form @submit.prevent="submitForm">
        <div class="form-group">
          <label>Your Name <span class="req">*</span></label>
          <input
            id="input-name"
            class="form-control"
            type="text"
            required
            v-model="name"
          />
        </div>

        <div class="form-group">
          <label>Email Address <span class="req">*</span></label>
          <input
            id="input-email"
            class="form-control"
            type="email"
            required
            v-model="email"
          />
        </div>

        <div class="form-group">
          <label>Contact Number <span class="req">*</span></label>
          <input
            id="input-phone"
            class="form-control"
            type="text"
            required
            v-model="phone"
          />
        </div>

        <div class="form-group">
          <label>Enter Your Message <span class="req">*</span></label>
          <textarea
            id="input-message"
            class="form-control"
            rows="5"
            placeholder="What can we help you with ?"
            required
            v-model="message"
          />
        </div>

        <button
          id="btn-contactFormSubmit"
          class="btn btn-submit btn-block"
          type="submit"
          name="submit"
        >
          Send Your Message
        </button>
        <br class="visible-xs" /><br class="hidden-xs" />
      </form>
    </div>

    <div class="col-md-4 col-lg-3 contact-card">
      <fieldset>
        <h4 class="nogap-bottom"><label>Email:</label></h4>
        <p><a href="mailto:info@ramjayinc.com">info@ramjayinc.com</a></p>
      </fieldset>

      <fieldset>
        <h4 class="nogap-bottom"><label>Phone:</label></h4>
        <p class="nogap-bottom"><a href="tel:703-567-6272">703-567-6272</a></p>
        <p><a href="tel:1-888-RAMJAYINC">1-888-RAMJAYINC</a></p>
      </fieldset>

      <fieldset>
        <h4 class="nogap-bottom"><label>Fax:</label></h4>
        <p><a href="tel:703-373-7296">703-373-7296</a></p>
      </fieldset>

      <fieldset>
        <h4 class="nogap-bottom"><label>Address:</label></h4>
        <p class="nogap-bottom">Suite 303 85 S.</p>
        <p>Bragg St Alexandria, VA 22312</p>
      </fieldset>
    </div>

    <br />
  </div>
  <!-- end.page-wrapper -->
</template>

<script>
const FORMSPARK_ACTION_URL = "https://submit-form.com/rY8dfryH";

export default {
  name: "Contact",

  data() {
    return {
      name: "",
      email: "",
      phone: "",
      message: "",
    };
  },

  methods: {
    async submitForm() {
      await fetch(FORMSPARK_ACTION_URL, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Accept: "application/json",
        },
        body: JSON.stringify({
          Name: this.name,
          Email: this.email,
          Phone: this.phone,
          Message: this.message,
        }),
      })
        .then(function (response) {
          if (response.status == 200) {
            document
              .getElementById("alert-success_contact")
              .classList.remove("hidden");
          } else {
            document
              .getElementById("alert-error_contact")
              .classList.remove("hidden");
          }
        })
        .catch(function (response) {
          console.error(response);
          document
            .getElementById("alert-error_contact")
            .classList.remove("hidden");
        });
    },
  },
};
</script>
