<template>
  <div class="row page-wrapper">
    <div class="col-xs-12 page-header">
      <h1 class="text-center">About <small>Ramjay Inc.</small></h1>
    </div>

    <div class="col-md-8 col-md-offset-2">
      <p>
        Ramjay Inc was founded in 2001, as an emerging shuttle service company.
        Today it has grown to provide transportation solutions to businesses,
        communities and individuals, indulging our customers with the utmost in
        personalized customer service. We serve just over 3000 passengers a day,
        throughout the Greater Washington Metropolitan Area. As experienced
        travel specialists, we will provide the ultimate service for your
        individual or organization’s transportation needs. We pride ourselves as
        being one of the more premier transportation services in the Greater
        Washington Metropolitan area, having set the standard in offering first
        class service, safety, dependability and convenience.
      </p>

      <p>
        We work hard to ensure that we enforce the solid reputation for
        excellence which sets us apart from the rest. Punctuality is a factor
        which we do not take lightly. We measure our commitment to perfection by
        our promptness in getting our clients to their destination on time. Our
        fleet boasts a variety of options, equipped in luxury and comfort. They
        range from sleek, stylish stretch limousines from 8 to 30 passengers,
        luxury sedans and SUVs that exude class, 15 passenger vans, 15, 25, 35,
        40 and 57 passenger buses and a wide range of party coaches of up to 50
        passengers, at the hands of experienced and professional chauffeurs,
        providing comfortable, luxurious and reliable services for any type of
        transportation requirement. Here at Ramjay, we value your business at
        the most esteemed levels.
      </p>

      <p>
        As a company, our work ethic oozes professionalism, yet at the same time
        we make it a point to make our customers feel like family from day one,
        treating every query or concern with highest priority. So go ahead, make
        the change to Ramjay Inc. for all your transportation needs. Your
        satisfaction is our ultimate priority!
      </p>

      <br />
    </div>

    <!-- <div class="col-md-4">
      <ReservationWidget />
    </div> -->

    <br class="visible-xs visible-sm visible-md" />
  </div>
</template>

<script>
// import ReservationWidget from "@/views/components/ReservationWidget.vue";

export default {
  name: "About",

  /*components: {
    ReservationWidget,
  },*/
};
</script>
