<template>
  <header>
    <div class="row">
      <div class="col-md-12">
        <a class="inline pull-left" href="/"
          ><img class="img-responsive" id="logo" src="/img/logo.png" alt=""
        /></a>
        <div id="header-contact" class="pull-right text-right">
          <a
            class="social-media pull-left"
            href="https://www.facebook.com/pages/Ramjay-Inc/183901098299069"
            target="_blank"
            ><i class="fa fa-facebook-square"></i
          ></a>
          <p class="nogap-bottom">
            <a class="tel" href="tel:703-567-6272">703-567-6272</a>
          </p>
          <p class="nogap-bottom">
            <a class="tel" href="tel:1-888-RAMJAYINC">1-888-RAMJAYINC</a>
          </p>
        </div>
      </div>
    </div>
    <!-- end.row -->

    <div class="row">
      <div class="navbar navbar-default hidden-xs" role="navigation">
        <div class="container">
          <div class="navbar-collapse collapse">
            <ul class="nav navbar-nav">
              <li><router-link to="/">Home</router-link></li>
              <li><router-link to="/about">About</router-link></li>
              <li><router-link to="/services">Services</router-link></li>
              <li><router-link to="/vehicles">Vehicles</router-link></li>
              <li><router-link to="/contact-us">Contact Us</router-link></li>
            </ul>
          </div>
          <!-- end.nav-collapse -->
        </div>
        <!-- end.container -->
      </div>
      <!-- end.navbar-default -->
    </div>
    <!-- end.row -->
  </header>

  <router-view />

  <div id="sub-footer" class="row">
    <div class="col-md-3 hidden-xs hidden-sm">
      <h3>Quick Links</h3>

      <ul>
        <li><router-link to="/">Home</router-link></li>
        <li><router-link to="/about">About</router-link></li>
        <li><router-link to="/services">Services</router-link></li>
        <li><router-link to="/vehicles">Vehicles</router-link></li>
        <!-- <li>
          <router-link to="/reservations/create">Reservations</router-link>
        </li> -->
        <li><router-link to="/contact-us">Contact Us</router-link></li>
      </ul>
    </div>

    <div class="col-sm-6 col-md-3">
      <h3>We Are Here</h3>

      <div itemscope itemtype="http://schema.org/PostalAddress">
        <p><span itemprop="name">Ramjay Inc.</span></p>
        <p><span itemprop="postOfficeBoxNumber">Suite 303</span></p>
        <p><span itemprop="addressLocality">85 S. Bragg St</span></p>
        <p><span itemprop="addressRegion">Alexandria, VA</span></p>
        <p><span itemprop="postalCode">22312</span></p>
        <p>
          <a
            href="https://www.google.com/maps/place/85+S+Bragg+St+%23204,+Alexandria,+VA+22312/@38.815186,-77.142401,15z/data=!4m2!3m1!1s0x89b7b25353879c7d:0xc3945897a03688ea?hl=en-US"
            target="_blank"
            >[Get Directions]</a
          >
        </p>
      </div>
    </div>

    <div class="col-sm-6 col-md-3">
      <h3>Contact Us</h3>

      <p class="nogap-bottom"><label>Phone:</label></p>
      <p class="nogap-bottom"><a href="tel:703-567-6272">703-567-6272</a></p>
      <p><a href="tel:1-888-RAMJAYINC">1-888-RAMJAYINC</a></p>

      <p class="nogap-bottom"><label>Fax:</label></p>
      <p><a href="tel:703-373-7296">703-373-7296</a></p>

      <p class="nogap-bottom"><label>E-mail:</label></p>
      <p><a href="mailto:info@ramjayinc.com">info@ramjayinc.com</a></p>
    </div>

    <div class="col-xs-12 col-md-3">
      <h3>SSI Protection</h3>

      <p>
        <a
          id="ssi-logo"
          class="imglink"
          href="http://ssiprotection.net/"
          target="_blank"
        ></a>
      </p>
      <p>
        The highest level of protection under the law and protect them against
        unjust litigation.
      </p>
      <a href="http://www.ssiprotection.net/" target="_blank"
        >[SSI Protection]</a
      >
    </div>
  </div>

  <footer class="row">
    <p id="copyright" class="text-center">
      Copyright &copy; {{ new Date().getFullYear() }} Ramjay Inc.
    </p>
    <p id="devs" class="text-center">
      Made with <span class="glyphicon glyphicon-heart"></span> by
      <a href="http://olionsoft.com.lk/" target="_blank">Olionsoft</a>
    </p>
  </footer>
</template>
